export const selectMyProperties = (state) => state.user.myProperties;

export const selectMyPackageSlots = (state) => state.user.myPackageSlots;

export const selectMyPackageSlotsDetails = (state) =>
  state.user.myPackageSlotsDetails;

export const selectMyDetails = (state) => state.user.user;

export const selectInvoiceDetails = (state) => state.user.invoiceDetails;

export const selectHasInvoiceDetails = (state) => state.user.hasInvoiceDetails;

export const selectFavoriteProperties = (state) =>
  state.user.favoriteProperties;

export const selectUserSearches = (state) => state.user.userSearches;

export const selectUserTransactions = (state) => state.user.transactions;
