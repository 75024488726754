import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from './Layout';
import { Container, Row, Col } from 'reactstrap';
import UserMenu from '../UserMenu';
import { fetchMyDetails, fetchInvoiceDetails } from '@a1-ict/fsbo/userSlice';
import { selectMyDetails } from '@a1-ict/fsbo/userSlice/selectors';
import { settings } from '../../settings';

const DashboardLayout = ({ children, title, childrenContainerClass = '' }) => {
  const dispatch = useDispatch();
  const myDetails = useSelector(selectMyDetails);

  useEffect(() => {
    dispatch(fetchMyDetails());
    dispatch(fetchInvoiceDetails());
  }, []);

  return (
    <Layout navColor="white" margined={true} showMenu={false}>
      <Container style={{ minHeight: '500px' }} fluid="xl">
        <Row>
          <Col
            md={3}
            style={{ borderRight: '1px solid #E4E4E4' }}
            className="d-none d-md-block p-md-1 p-0"
          >
            <div className="h-100 w-100">
              <div className="mx-2 mt-5 ">
                {myDetails && <p>Здравейте, {myDetails.full_name}! </p>}
              </div>
              <UserMenu customClass="dark"></UserMenu>
            </div>
          </Col>
          <Col xs={12} md={9}>
            <Container fluid className="p-md-5">
              <Row className="mt-3 mb-5">
                <h1 className="h1">{title}</h1>
              </Row>
              <Row>
                <Col xs={12} className={childrenContainerClass}>
                  {children}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default DashboardLayout;
