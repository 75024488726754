import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { settings } from '../../settings';

import {
  selectIsLoggedIn,
  selectIsRefreshingToken,
  selectInitingApp,
} from '@a1-ict/core/authSlice/selectors';

import PrivateSpinner from './privateSpinner';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isRefreshingToken = useSelector(selectIsRefreshingToken);
  const initingApp = useSelector(selectInitingApp);

  if (!settings.storage.isBrowser) return null;

  useEffect(() => {
    if (!isRefreshingToken && !isLoggedIn && !initingApp) {
      navigate(`/`);
      return null;
    }
  }, [isRefreshingToken, isLoggedIn, initingApp]);

  return isRefreshingToken || initingApp ? <PrivateSpinner /> : children;
};

export default PrivateRoute;

