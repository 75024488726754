import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../../components/layouts/DashboardLayout';
import PrivateRoute from '../../../components/Security/privateRoute';
import { Row, Col, Label, Input, Button } from 'reactstrap';
import { fetchNotifications, setNotifications } from '@a1-ict/fsbo/notificationsSlice';
import Pagination from '../../../components/Common/Pagination';
import { deleteNotification, readNotification } from '@a1-ict/fsbo/notificationsSlice/requests';
import { selectNotifications } from '@a1-ict/fsbo/notificationsSlice/selectors';
import { useLocation } from '@reach/router';

const Messages = () => {
  const [forceRerender, setForceRerender] = useState(Date.now());
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [forcePage, setForcePage] = useState(0);
  const location = useLocation();
  const messageId = location.pathname.split('/').pop();

  const topOfList = useRef(null);

  const dispatch = useDispatch();

  const newNotifications = useSelector(selectNotifications);

  useEffect(async () => {
    const result = await dispatch(fetchNotifications({ queryParams: {} }));
    if (result?.payload?.ok) {
      setAllMessages(result.payload.data.items);
      setTotalPages(result.payload.data.total_pages);
      setForceRerender(Date.now());

      const elementOfId = document.getElementById(messageId);
      if (elementOfId) {
        elementOfId.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        handleMessageClicked(elementOfId);
      }
    }
  }, []);

  useEffect(() => {
    if (newNotifications) {
      setAllMessages(newNotifications.items);
      setTotalPages(newNotifications.total_pages);
      setForcePage(0);
    }
  }, [newNotifications]);

  const handleMessageSelected = (selector) => {
    if (selector === 'all') {
      if (selectedMessages.length === allMessages.length) {
        setSelectedMessages([]);
      } else {
        setSelectedMessages(allMessages);
      }
    } else {
      let currentSelected = [...selectedMessages];

      const index =
        currentSelected.length > 0 ? currentSelected.findIndex((o) => o['id'] === selector) : -1;
      if (index === -1) {
        currentSelected.push(allMessages.find((not) => not.id === selector));
      } else {
        currentSelected.splice(index, 1);
      }
      setSelectedMessages(currentSelected);
    }
  };

  const handleMessageClicked = (id) => {
    let curMessages = [...allMessages];
    const indexOfMessage = allMessages.findIndex((m) => m['id'] === id);
    if (indexOfMessage > -1 && curMessages[indexOfMessage].read === false) {
      let obj = { ...curMessages[indexOfMessage] };
      obj.read = true;
      curMessages[indexOfMessage] = obj;
      setAllMessages(curMessages);
      dispatch(readNotification({ id: id }));
    }
  };

  const handleDelete = async () => {
    const selectedIds = selectedMessages.map((m) => m.id);
    const result = await dispatch(deleteNotification({ ids: selectedIds }));
    if (result?.payload?.ok) {
      let updatedMessages = allMessages.filter((message) => !selectedIds.includes(message.id));
      setAllMessages(updatedMessages);
      dispatch(fetchNotifications({ queryParams: {} }));
    }
  };

  const handlePageClick = async (data) => {
    const result = await dispatch(
      fetchNotifications({ queryParams: { CurrentPage: data.selected + 1 } })
    );
    if (result?.payload?.ok) setAllMessages(result.payload.data.items);
    topOfList.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  return (
    <PrivateRoute>
      <DashboardLayout title="Съобщения">
        <div ref={topOfList} className="notifications_section mb-5">
          <Row className="shadow-primary bg-white pl-3 py-3 pr-2">
            <Col xs={12} className=" d-flex justify-content-between align-items-center">
              <Label check key={forceRerender}>
                <Input
                  checked={selectedMessages.length === allMessages?.length}
                  onChange={() => {
                    handleMessageSelected('all');
                  }}
                  type="checkbox"
                />{' '}
                Избери всички
              </Label>
              <Button
                disabled={selectedMessages.length === 0 && true}
                color="secondary"
                className="btn btn-secondary"
                onClick={handleDelete}
              >
                Изтрий
              </Button>
            </Col>
            <Col xs={12}>
              {allMessages?.length > 0 ? (
                allMessages.map((note, index) => {
                  return (
                    <div id={note.id} key={index} className="d-flex align-items-center my-3 w-100">
                      <div style={{ width: '30px' }} className="">
                        {' '}
                        <Label check>
                          <Input
                            onChange={() => handleMessageSelected(note.id)}
                            checked={selectedMessages.some((o) => o['id'] === note['id'])}
                            type="checkbox"
                          />
                        </Label>
                      </div>
                      <div
                        className={`notifications_section__note d-flex flex-column w-100 ${note.read ? 'bg-default' : 'bg-grey-2'
                          }  p-3`}
                        onClick={() => handleMessageClicked(note.id)}
                      >
                        <h4 className="h4">{note.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: note.body }} />
                        <span className="text-danger">
                          {note.created_on}
                          {/* {new Date().toLocaleDateString('en-GB', { timeZone: 'UTC' })} */}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h6>Все още нямате съобщения</h6>
              )}

              <div
                className="pt-2  d-flex justify-content-center align-items-center "
                key={forceRerender}
              >
                {totalPages > 1 && (
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={totalPages}
                    forcePage={forcePage}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default Messages;
